.number_arrow_box {
  position: relative;
}
.number_arrow_box:after,
.number_arrow_box:before {
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.number_arrow_box:after {
  border-color: rgba(0, 0, 0, 0);
  border-right-color: #000;
  border-width: 8px;
  margin-top: -8px;
}
.number_arrow_box:before {
  border-color: rgba(0, 0, 0, 0);
  border-right-color: #000;
  border-width: 14px;
  margin-top: -14px;
}
